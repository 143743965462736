@tailwind base;
@tailwind components;
@tailwind utilities;

@layer header {
  .header-item {
    @apply block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0;
  }
  .header-item-active {
    @apply block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0;
  }
  .header-item-cta {
    @apply block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0;
  }
}

@font-face {
  font-family: 'Alatsi';
  src: local('Alatsi'), url(../public/fonts/Alatsi/Alatsi-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Alef';
  src: local('Alef'), url(../public/fonts/Alef/Alef-Regular.ttf) format('truetype');
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Alatsi', Helvetica, sans-serif;
}

body, div, p{
  font-family: 'Alef', Helvetica, sans-serif;
}

.tsparticle-overlay {
  position: fixed;
  width: 100%;
  z-index: 1;
}

body {
  margin: 0;
  font-family: primary-semibold, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animated-input {
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: 4px 4px black;
}

.animated-input:focus {
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: 4px 4px black;
}

.animated-image {
  cursor: pointer;
  outline: none;
  color: #fff;
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: 4px 4px black;
}

.animated-image:hover {
  opacity: .6;
}

.animated-image:active {
  opacity: .6;
  box-shadow: 2px 2px #666;
  transform: translateY(4px);
}

.animated-image-box {
  cursor: pointer;
  outline: none;
  color: #fff;
  border-radius: 12px;
  box-shadow: 3px 3px black;
}

.animated-image-box:hover {
  opacity: .6;
}

.animated-image-box:active {
  opacity: .6;
  box-shadow: 2px 2px black;
  transform: translateY(4px);
}

.animated-link {
  cursor: pointer;
  outline: none;
  color: black;
}

.animated-link:hover {
  opacity: .6;
}

.animated-link:active {
  opacity: .6;
  transform: translateY(4px);
}


.marquee {
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee-content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll 40s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

      

