.content-container {
    display: flex;
    min-height: calc(100vh - 5px);
    flex-direction: column;
}

.main-container {
    flex: 1;
}

.footer-container {
    height: 50px;
}